.dropdownMenuItem {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  display: inline-block;
  font-size: 24px;
}

.title {
  white-space: nowrap;
  flex-grow: 1;
  padding: 0 4px;
}

.submenu {
  z-index: 6;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: white;
}
