.container {
  position: relative;
  display: inline-block;
}

.button {
  outline: none;
  border: none;
  background-color: white;
  font-family: inherit;
  font-size: 24px;
  cursor: pointer;
}

.buttonFocus {
  background-color: #f5f5f5;
  border-radius: 50%;
}

.dropdownMenu {
  list-style: none;
  z-index: 5;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 0;
  margin: 0;
  background: white;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.dropdownMenuItem {
  padding: 11px 4px;
  min-width: 50px;
  cursor: pointer;
}

.dropdownMenuItem:hover {
  background-color: #f5f5f5;
}
