.toggle-label {
  display: inline-flex;
  float: left;
  line-height: 25px;
  margin-right: 16px;
  max-width: 167px;
  min-width: 45%;
  font-size: 14px;
}

.box_1 {
  background: #eee;
}

input[type='checkbox'].toggle {
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].toggle:checked {
  background: #1b87e6;
}

input[type='checkbox'].toggle:after {
  position: absolute;
  content: '';
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type='checkbox'].toggle:checked:after {
  left: calc(100% - 1.5em);
}
