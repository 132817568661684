html,
body,
#root {
  height: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
}

* {
  color: #545e6b;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
